import { HTMLProps } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { isEmptyString } from '@/lib/utils.ts';
import routes from '@/lib/routes.tsx';
import { useNavigate } from 'react-router-dom';

interface Interface {
	reviewerName?: string;
	hideTitle?: boolean;
	userId: string;
	coins?: number;
	deviceBanCount: number;
	deviceAccountsCount: number;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ProfileBottomStats({
	coins,
	userId,
	reviewerName,
	hideTitle = false,
	deviceAccountsCount,
	deviceBanCount,
	className,
}: Interface) {
	const navigate = useNavigate();

	const goToSubAccounts = () => {
		navigate(`${routes.nav.profileDetails}/${userId}/${routes.nav.subAccounts}`);
	};

	return (
		<div className={`flex flex-col items-center justify-center gap-1 ${className}`}>
			{hideTitle ? null : (
				<span className={'text-center font-semibold'}>Plus d'infos</span>
			)}
			{coins! > 0 ? (
				<span className={'text-center text-sm text-gray-500'}>
					🪙 Pièces: {coins!.toLocaleString()}
				</span>
			) : null}
			{deviceBanCount > 0 ? (
				<span className={'text-center text-sm text-gray-500'}>
					🚫 Deja banni: {deviceBanCount} fois
				</span>
			) : null}
			{deviceAccountsCount > 1 ? (
				<span className={'text-center text-sm text-gray-500'}>
					🆕 Nombre de comptes actifs: {deviceAccountsCount}
					{deviceAccountsCount >= 2 && (
						<Button size="sm" variant="link" onClick={goToSubAccounts}>
							Voir
						</Button>
					)}
				</span>
			) : null}
			{!isEmptyString(reviewerName) ? (
				<span className={'text-center text-sm text-gray-500'}>
					✅ Certifié par: {reviewerName}
				</span>
			) : null}
		</div>
	);
}
